<template>
  <div class="pages hidden" id="diagnosisValue">
    <page-title id="diagnosisValueTxt" ref="refPageTitle" />
    <div class="update_date hidden" id="updateDate"></div>
    <div ref="refDiagnosisValueHolder" v-if="isChartVisible">
      <chart-legend ref="refDiagnosisValueLegend" id="refDiagnosisValueLegend" class="common_legend" />
      <bar-double-chart ref="refDiagnosisValueChart" id="refDiagnosisValueChart" customClass="bar_double_chart" />
    </div>
    <div class="chart_desc hidden" id="chartDesc"></div>
  </div>
</template>
<script>
//特别注意：使用jquery的时候为了避免重名带来的干扰，选择器开头必须是marketChart
import $ from "jquery";
import anime from "animejs/lib/anime.es.js";
import { anyElement } from "@/components/anyElement.js";
import { sleep, setRem } from "@/utils/utils.js";
import pageTitle from "@/businessComp/charts/pageTitle/index.vue";
import barDoubleChart from "@/components/barDoubleChart/index.vue";
import chartLegend from "@/components/chartLegend/index.vue";

export default {
  data: function () {
    return {
      titleText: null, //动画句柄
      colorArr: ["#FF8000", "#FFC400"],
      isChartVisible: false,
    };
  },
  components: {
    pageTitle,
    barDoubleChart,
    chartLegend,
  },
  methods: {
    preview(commandData) {
      setRem(1080);
      this.show(commandData)
    },
    async hide() {
      return new Promise(async (resolve, reject) => {
        this.$refs.refPageTitle.hide();
        await sleep(200);
        $("#diagnosisValue #updateDate").addClass("hidden");
        $("#diagnosisValue #chartDesc").addClass("hidden");
        this.isChartVisible = false;
        resolve();
      });
    },
    async show(commandData) {
      this.isChartVisible = true;
      $("#diagnosisValue").removeClass("hidden");

      //设置标题
      this.$refs.refPageTitle.show(commandData);

      this.$nextTick(async () => {
        $("#diagnosisValue #updateDate").html(commandData.data.date);
        $("#diagnosisValue #chartDesc").html(commandData.data.desc);
        await sleep(300);
        this.handleDiagnosisValue(commandData);
        $("#diagnosisValue #updateDate").removeClass("hidden");
        this.updateDateHandler = anyElement({
          targets: document.querySelector("#diagnosisValue #updateDate"),
          isSplitTxt: false,
          duration: 1200,
          delay: 50,
          easing: "easeOutExpo",
          inEffect: "rightIn",
          outEffect: "leftOutBig",
        });
        this.updateDateHandler.runIn();

        $("#diagnosisValue #chartDesc").removeClass("hidden");
        this.chartDescHandler = anyElement({
          targets: document.querySelector("#diagnosisValue #chartDesc"),
          isSplitTxt: true,
          duration: 600,
          delay: 40,
          easing: "easeOutExpo",
          inEffect: "bottoIn",
          outEffect: "leftOutBig",
        });
        await sleep(200);
        this.chartDescHandler.runIn();
      });
    },
    handleDiagnosisValue(commandData) {
      this.isChartVisible = true;
      this.$nextTick(() => {
        this.renderTableChart(commandData);
      });
    },
    renderTableChart(commandData) {
      //展示legend
      this.$refs.refDiagnosisValueLegend.createLegend({
        legend: commandData.data.legend,
        legendType: "bar",
        colors: this.colorArr,
      });
      let width = this.$refs.refDiagnosisValueHolder.clientWidth;
      this.$refs.refDiagnosisValueChart.BarChart(commandData.data.data, {
        x: (d) => d.label,
        width: width,
        height: width * 0.57,
        marginLeft: 90,
        marginRight: 50,
        marginTop: 50,
        marginBottom: 50,
        zPadding: 0.05,
        xPadding: 0.3,
        duration: 800,
        delay: 80,
        ease: "easeBack",
        xTickNumber: commandData.data.data.length,
        yTickNumber: 4,
        colors: this.colorArr,
        isShowText: false,
      });
    },
  },

  async mounted() {
    window.hide = this.hide;
    window.show = this.preview;

    this.$bus.$on("chartExit", () => {
      this.hide();
    });
  },
};
</script>
<style lang="less" scoped>
@import "index.less";
</style>
